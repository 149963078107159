/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"

import { ContextProvider } from "./src/context/app-context"
import './src/styles/index.scss'
import './src/shared/styles/index.scss'

export const wrapRootElement = ({ element }) => (
  <ContextProvider>{element}</ContextProvider>
)