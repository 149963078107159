import React from 'react'

let initialState = {
  modalActive: null,
}

let Context = React.createContext(initialState)

let reducer = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload }
    case 'reset':
      return { ...initialState }
    default:
      return { ...state }
  }
}

function ContextProvider(props) {
  let [state, dispatch] = React.useReducer(reducer, initialState)
  let value = { ...state, state, dispatch }

  return <Context.Provider value={value}>{props.children}</Context.Provider>
}

let ContextConsumer = Context.Consumer

export { Context, ContextProvider, ContextConsumer }
